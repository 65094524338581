import React from 'react';

import Navbar from '../Header/Navbar';
import Footer from '../Footer';


const LayoutCliente = ({children}) => {
    
    return (
        <>
        <Navbar />
            {children}
        <Footer />
        </>
    );
}
 
export default LayoutCliente;
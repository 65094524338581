import React from 'react'
import { Link } from 'react-router-dom';
import imgLogo from '../img/logo.svg';

const Footer = () => {
    return ( 
        <footer>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3">
                        <img alt="Logo Laberinto" src={imgLogo} />
                    </div>
                    
                    <div className="col-md-3">
                        <h4 className="title_footer">Info del Parque</h4>
                        <ul>
                            <li><Link to={"/horarios"}>Horarios</Link></li>
                            <li><Link to={"/comoLlegar"}>Como llegar</Link></li>
                            <li><Link to={"/accesibilidad"}>Accesibilidad</Link></li>
                            <li><Link to={"/pregFrecuentes"}>Preguntas Frecuentes</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <h4 className="title_footer">Atracciones</h4>
                        <ul>
                            <li><Link to={"/laberinto"}>Laberinto</Link></li>
                            <li><Link to={"/tematico"}>Temático</Link></li>
                            <li><Link to={"/juegos"}>Juegos</Link></li>
                            <li><Link to={"/servicios"}>Servicios</Link></li>
                        </ul>
                    </div>
                    <div className="col-md-3">
                        <h4 className="title_footer">Seguinos en las redes!</h4>
                        <ul className="social-network social-circle">
                            <li><a href="https://www.facebook.com/laberinto.lastoninas/" target="_blank" rel="noopener noreferrer" className="icoFacebook" title="Facebook"><i className="fa fa-facebook"></i></a></li>
                            <li><a href="https://api.whatsapp.com/send?phone=5491169354649" className="icoYoutube" title="Facebook" target="_blank" rel="noopener noreferrer"><i className="fa fa-whatsapp"></i></a></li>
                            <li><a href="https://www.instagram.com/laberintolastoninas/" className="icoInsta" title="Instagram" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 copy">
                        <p className="text-center">&copy; 2022 Todos los derechos son propiedad del Coordinador General.<br></br><span>Desarrollado por <a className="desarrollado-por" href="https://alejobocanegra.com/" target="_blank" rel="noopener noreferrer">Alejo Bocanegra</a></span></p>
                        
                    </div>
                </div>
                            
            </div>
        </footer>
    );
}

export default Footer;
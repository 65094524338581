import React from 'react';
import imgHeader1 from '../../img/IMG_9047.JPG';


const Header = () => {

    
    return (
             
            <header>
                <img className="img-slider-header" src={imgHeader1} alt="Juego del Laberinto" />
            </header>  
     );
}

export default Header;
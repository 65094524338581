import React from "react";
import Slide from 'react-reveal/Slide';
import CardAcordeon from "../CardAcordeon";

const PregFrecuentes = () => {
  return (
        <div className="bg margen-arriba">
            <Slide top>
                <h3 className="title">PREGUNTAS FRECUENTES</h3>
            </Slide>
            <div className="container">
                
                <div id="accordion" className="preguntas">
                    <CardAcordeon 
                        idHeader="headingTwo"
                        idCollapse="collapseTwo"
                        titulo="¿Cuanto tiempo lleva recorrerlo?"
                        descripcion="Puede disfrutar del parque en forma gratuita por varias horas. Recorrer el juego del Laberinto lleva aproximadamente 40 minutos."
                    />
                    <CardAcordeon 
                        idHeader="headingThree"
                        idCollapse="collapseThree"
                        titulo="¿Se puede ingresar con Mascotas?"
                        descripcion="No, no esta permitido el ingreso al predio con mascotas, cualquiera sea su tamaño o especie."
                    />
                    <CardAcordeon 
                        idHeader="headingFour"
                        idCollapse="collapseFour"
                        titulo="¿Se abona estacionamiento?"
                        descripcion="Los vehiculos quedan estacionados en la vía pública. Contamos con un espacio reservado para personas con movilidad reducida."
                    />
                    <CardAcordeon 
                        idHeader="headingFive"
                        idCollapse="collapseFive"
                        titulo="¿Esta permitido el ingreso con Alimentos al parque?"
                        descripcion="Si, se puede ingresar al predio con alimentos. Igualmente el parque cuenta con Kiosco-Buffet."
                    />
                    <CardAcordeon 
                        idHeader="headingSix"
                        idCollapse="collapseSix"
                        titulo="¿Las personas discapacitadas abonan?"
                        descripcion="Quienes Poseen CUD (Certificado Unico de Discapacidad) no abonan el ingreso al juego del Laberinto. Deben tener en cuenta que el juego esta construido sobre medanos naturales de arena, por lo que se dificulta el ingreso a personas con movilidad reducida."
                    />
                    <CardAcordeon 
                        idHeader="headingSeven"
                        idCollapse="collapseSeven"
                        titulo="¿Los residentes del Partido de la Costa abonan normalmente?"
                        descripcion="Los residentes del Partido de la Costa deben presentar Tarjeta Única Municipal para no abonar el ingreso al juego."
                    />
                    <CardAcordeon 
                        idHeader="headingEight"
                        idCollapse="collapseEight"
                        titulo="¿Se puede ingresar si llueve?"
                        descripcion="Si hay alerta Meteorológica y tormenta no se permite el ingreso al juego."
                    />
                    <CardAcordeon 
                        idHeader="headingNine"
                        idCollapse="collapseNine"
                        titulo="¿Con que servicios cuenta el parque?"
                        descripcion="El parque cuenta con un Laberinto natural de 6400 m2, Una carpa gigante con mesas y sillas para tomar mate, circuito artesanal, kiosco, Cantina, cabañas de ventas de artesanias, Wifi gratuito, cancha de Voley, Aro de Basquet, Tejo, arco de Futbol y Rayuela para jugar en Familia."
                    />
                     <CardAcordeon 
                        idHeader="headingTen"
                        idCollapse="collapseTen"
                        titulo="¿Se puede ingresar descalzo?"
                        descripcion="Para ingresar al juego del Laberinto, se sugiere ingresar con calzado adecuado para protegerse de la arena caliente. Los dias de mucho calor recomendamos utilizar gorra, protector solar y llevar agua para hidratarse."
                    />
                </div>
            </div>
        </div>
  );
};

export default PregFrecuentes;

import React, { useState} from 'react';
import Spinner from '../Spinner';
import axios from 'axios';


const Contacto = () => {

    const [formulario, modificarForm] = useState({
        nombre: '',
        email: '',
        mensaje: ''
    });
    const [cargando, guardarCargando] = useState(false);
    const [mostrarBtn, guardarBtn] = useState(true);

    const {nombre, email, mensaje} = formulario;


    const actualizarState = e => {
        modificarForm({
            ...formulario,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        guardarBtn(false);
        guardarCargando(true);
        const dataToSubmit = {
            nombre: nombre,
            email: email,
            mensaje: mensaje
        }
        
        axios.post('https://laberintolastoninas.com.ar/api-laberinto/consulta.php',dataToSubmit).then(res => {
            alert("Su Consulta se ha enviado con exito! En breve, recibiras una respuesta por mail");
           
            guardarCargando(false);
            guardarBtn(true);
            modificarForm({
                nombre: '',
                email: '',
                mensaje: ''
            });
        }).catch(()=>{
            alert("Ha ocurrido un error, Su mensaje no se ha enviado, Por favor reintente nuevamente");
            
          })
        

    }

    return (
        <div className="bg">
        <div className="container margen-arriba">
            <div className="row">
                <div className="col-md-6">
                    <div className="datos-contacto">
                        <h2 className="title">Contactate con Nosotros!</h2>
                        <ul className="social-network social-circle">
                            <li><a href="https://api.whatsapp.com/send?phone=5491169354649" target="_blank" rel="noopener noreferrer" className="icoFacebook" title="WhatsApp"><i className="fa fa-phone-volume"></i></a>&nbsp;&nbsp; (011)15 6935 4649</li>
                            <li><a href="https://www.facebook.com/laberinto.lastoninas/" target="_blank" rel="noopener noreferrer" className="icoFacebook" title="Facebook"><i className="fa fa-facebook"></i></a>&nbsp;&nbsp; @laberinto.lastoninas</li>
                            <li><a href="https://www.instagram.com/laberintolastoninas/" target="_blank" rel="noopener noreferrer" className="icoInsta" title="Instagram"><i className="fa fa-instagram"></i></a>&nbsp;&nbsp; @laberintolastoninas</li>
                            <li><a href="mailto:laberinto_lastoninas@yahoo.com.ar" target="_blank" rel="noopener noreferrer" className="icoFacebook" title="Mail"><i className="fas fa-envelope-open-text"></i></a>&nbsp;&nbsp; laberinto_lastoninas@yahoo.com.ar</li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-6">
                    <form onSubmit={handleSubmit} className="form-contacto" >
                        <div className="form-group">
                            <label htmlFor="nombre">Nombre:</label>
                            <input type="text" className="form-control"  onChange={actualizarState} name="nombre" value={nombre} placeholder="Ingrese Nombre" id="nombre" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email:</label>
                            <input type="email" className="form-control" onChange={actualizarState} name="email" value={email} placeholder="Ingrese Email" id="email" required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="mensaje">Mensaje:</label>
                            <textarea className="form-control" rows="4" onChange={actualizarState} name="mensaje" value={mensaje} placeholder="Ingrese Mensaje" id="mensaje" required />
                        </div>
                              
                        {mostrarBtn ? <button type="submit" className="btn">Enviar</button> : null}
                        {cargando ? <Spinner /> : null }
                    </form>
                    
                </div>
            </div>
        </div>
        </div>
     );
}
 
export default Contacto;
import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../img/logo.svg";

const Navbar = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [navbar, setNavbar] = useState(true);

  const changeBackground = () => {
    //console.log(window.scrollY);
    if (window.scrollY <= 30) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  const ocultarMenu = () => {
    let collapse = document.getElementById("navbarSupportedContent");
    if (collapse.classList.contains("show")) {
      collapse.classList.remove("show");
    }
  };

  window.addEventListener("scroll", changeBackground);
  return (
    <nav
      className={
        navbar
          ? "navbar navbar-expand-xl navbar-light fixed-top nav-transparent"
          : "navbar navbar-expand-xl navbar-light fixed-top nav-color"
      }
    >
      <NavLink className="navbar-brand" to={"/"}>
        <img
          className={navbar ? "logomenugrande" : "logomenuchico"}
          alt="logo Laberinto"
          src={logo}
        />
      </NavLink>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav  ml-auto">
          <li className="nav-item itemmenu">
            <NavLink
              className="nav-link"
              activeClassName="active"
              to={"/"}
              onClick={() => ocultarMenu()}
              exact
            >
              Inicio
            </NavLink>
          </li>
          <li className="nav-item dropdown itemmenu">
            <a
              className="nav-link dropdown-toggle"
              href="/#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Info del Parque
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <NavLink
                className="dropdown-item"
                activeClassName="active"
                to={"/horarios"}
                onClick={() => ocultarMenu()}
              >
                Horarios
              </NavLink>
              <NavLink
                className="dropdown-item"
                activeClassName="active"
                to={"/comoLlegar"}
                onClick={() => ocultarMenu()}
              >
                Como Llegar
              </NavLink>
             
            <NavLink
              className="dropdown-item"
              activeClassName="active"
              to={"/accesibilidad"}
              onClick={() => ocultarMenu()}
            >
              Accesibilidad
            </NavLink>
          
              <NavLink
                className="dropdown-item"
                activeClassName="active"
                to={"/pregFrecuentes"}
                onClick={() => ocultarMenu()}
              >
                Preguntas Frecuentes
              </NavLink>
            </div>
          </li>
          <li className="nav-item itemmenu">
            <NavLink
              className="nav-link"
              activeClassName="active"
              to={"/contingentes"}
              onClick={() => ocultarMenu()}
            >
              Contingentes
            </NavLink>
          </li>
         
          <li className="nav-item itemmenu ">
            {/*  <Pulse forever={true} delay={5000} duration={1500}> */}
            <NavLink
              className="nav-link parpadea"
              activeClassName="active desactiva-animacion"
              to={"/novedades"}
              onClick={() => ocultarMenu()}
            >
              Novedades
            </NavLink>
            {/*  </Pulse> */}
          </li>
          <li className="nav-item itemmenu">
            <a
              className="nav-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://api.whatsapp.com/send?phone=541169354649&text=Hola!%20quisiera%20reservar%20una%20fecha%20para%20visitar%20el%20parque.%20Gracias!"
            >
              HACÉ TU RESERVA
            </a>
          </li>

          <li className="nav-item itemmenu">
            <NavLink
              className="nav-link"
              activeClassName="active"
              id="contacto"
              to={"/contacto"}
              onClick={() => ocultarMenu()}
            >
              Contacto
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};
export default Navbar;

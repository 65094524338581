import React, { useContext } from "react";
import { Link } from "react-router-dom";
import authContext from "../../../context/autenticacion/authContext";

const Dashboard = () => {

  const AuthContext = useContext(authContext);
  const { cerrarSesion } = AuthContext;

  return (
    <div style={{ background: "#E4E3E3", height: "100vh", width: "100vw" }}>
        <h1 className="title text-center">PANEL DE ADMINISTRACIÓN</h1>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
          

            <div className="row">
              <div className="col-md-2"></div>
              <div className="col-md-8 mt-5 text-center">
                <Link className="boton-dashboard" to={"/admin/novedades"}>
                  ADMINISTRAR NOVEDADES
                </Link>

                <Link className="boton-dashboard" to={"/admin/horarios"}>
                  CAMBIAR HORARIOS
                </Link>

                <Link className="boton-dashboard" to={"/admin/marquesina"}>
                  CAMBIAR MARQUESINA
                </Link>

                <button
                  style={{ marginTop: "80px" }}
                  className="boton-dashboard"
                  onClick={() => cerrarSesion()}
                >
                  <i className="fas fa-power-off"></i> CERRAR SESIÓN
                </button>
              </div>
              <div className="col-md-2"></div>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

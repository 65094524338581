import React from "react";
import { useHistory } from "react-router-dom";

const Modal = ({ invocaModal }) => {
  let history = useHistory();

  const cierraModal = React.useRef(null);

  const verNovedades = () => {
    cierraModal.current.click();
    history.push("/novedades");
  }

  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Últimas Novedades
            </h5>
            <button
              ref={cierraModal}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body ">
            <p>¿Queres enterarte de las últimas Novedades del parque?</p>
            <p>Visitá la Sección Novedades y mantenete siempre actualizado con las noticias del Parque.</p>
          </div>
          <div className="modal-footer">
            <button onClick={verNovedades} className="btn btn-modal">
              VER NOVEDADES
            </button>
          </div>
        </div>
      </div>
      <button
        ref={invocaModal}
        type="button"
        className="btn btn-primary d-none"
        data-toggle="modal"
        data-target="#exampleModal"
      >
        Launch demo modal
      </button>
    </div>
  );
};

export default Modal;

import React from 'react';
import Card from './Card';
import imgMangrullo from '../../img/mangrullo.jpg';
import imgCaverna from '../../img/1.jpeg';
import imgJuegos from '../../img/juegos.jpg';
import imgServicios from '../../img/tienda.jpg';
const Section = () => {
    return (
        
            <article>
                <div className="container-fluid bg">
                <h3 className="home__title">Principales Atracciones</h3>
                <div className="row center-block">
                    <div className="col-md-3 col-sm-6 card_">
                        <Card 
                            urlImg={imgMangrullo}
                            titulo="Juego del Laberinto"
                            descripcion="Uno de los Laberintos más Grandes de Sudamérica te desafía a recorrer 6.400 m2 de superficie en un entorno natural, con mucha vegetación a solo metros del mar."
                            url="/laberinto"
                        />
                    </div>
                    <div className="col-md-3 col-sm-6  card_">
                        <Card 
                            urlImg={imgCaverna}
                            titulo="Sector temático"
                            descripcion="Emociónate con el Primer y Único Circuito Religioso del Partido de La Costa, visitado por miles de personas cada año, movilizadas por el amor y la fe de este hecho religioso y cultural."
                            url="/tematico"
                        />
                    </div>
                    <div className="col-md-3 col-sm-6 card_">
                        <Card 
                            urlImg={imgJuegos}
                            titulo="Sector de Juegos"
                            descripcion="El deporte y los juegos tradicionales hacen de tu visita un punto de unión familiar y de amigos, donde la diversión será el centro de una estadía placentera."
                            url="/juegos"
                        />
                    </div>
                    <div className="col-md-3 col-sm-6  card_">
                        <Card 
                            urlImg={imgServicios}
                            titulo="Servicios del Parque"
                            descripcion="Disponemos distintos servicios para que puedas disfrutar a pleno tu visita. Un kiosco/buffet, Wifi Gratuito y paseo de compras con productos regionales de primera calidad."
                            url="/servicios"
                        />
                    </div>
                </div>
                </div>
               
            </article>       
       
        
     );
}
 
export default Section;
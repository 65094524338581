import React from "react";
import Slide from "react-reveal/Slide";

const ItemSeccion = (props) => {
  if (props.position === "left") {
    return (
      <Slide bottom>
        <div className="card mb-3 labe-1">
          <div className="row no-gutters">
            <div className={(props.idNovedad) ? 'col-md-5 centrado-novedad ' : 'col-md-5 text-left'}>
              
                {(props.urlImg) ? <img src={props.urlImg} className="card-img" alt="..." /> : null }
                { (props.video) ? <iframe className="iframe-novedad-sinvideo iframe-novedad"  src={props.video} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> : null}
             
            </div>
            <div className="col-md-7">
              <div className="card-body">
                <h5 className="card-title">{props.titulo}</h5>
                {props.descripcion}
              </div>
            </div>
          </div>
        </div>
      </Slide>
    );
  } else {
    return (
      <div className="card mb-3">
        <div className="row no-gutters">
          <div className="col-md-8 ">
            <div className="card-body">
              <h5 className="card-title">{props.titulo}</h5>
              {props.descripcion}
            </div>
          </div>
          <div className="col-md-4 ">
            <img src={props.urlImg} className="card-img" alt="..." />
          </div>
        </div>
      </div>
    );
  }
};

export default ItemSeccion;

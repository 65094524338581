import React, { Fragment } from 'react';
import Slide from 'react-reveal/Slide';
import ItemSeccion from '../ItemSeccion';
import Slider from '../Slider';
import img1 from '../../img/contingentes/fogon1.jpeg';
import img2 from '../../img/contingentes/IMG_9028.JPG';
import img3 from '../../img/contingentes/full.png';


const Contingentes = () => {

    const imagesSlider = [
        'contingentes/contin1.jpeg',
        'contingentes/contin3.jpeg',
        'contingentes/contin4.jpeg',
        'contingentes/contin5.jpeg',
        'contingentes/contin6.jpeg',
        'contingentes/contin8.jpeg'
    ];

    return (
        <div className="bg margen-arriba">
                <Slide top>
                 <h2 className="title">GRUPOS (Estudiantil, Jubilados, Contingentes)</h2>
                </Slide>
                <div className="container">
                   
                    <Slider 
                        imagenes={imagesSlider}      
                    />
                    <div className="contenido">
                        <div className="pequeña-bajada">
                            <h3 className="exp-laberinto">Experiencia LABERINTO!</h3>
                            <p>Bienvenidos a  una nueva experiencia… Desafío, aventura, diversión y entretenimiento son parte del atractivo que te permitirá vivir momentos únicos e inolvidables.</p>
                        </div>

                        <ItemSeccion
                            position='left'
                            urlImg={img3}
                            titulo='Laberinto'
                            descripcion={
                            <ul className="text-left">
                                <li>6.400 m2 de uno de los Laberintos naturales más grandes de Argentina, único a metros del mar y por ser de médano vivo.</li>
                                <li>1.700 metros de caminos internos para recorrer y encontrar la salida!</li>
                                <li>Un Barco Pirata y el auto de los Picapiedras que deberán descubrir</li>
                                <li>El mirador de 10 metros de altura que te permite ver la salida y hasta el mar</li>
                            </ul>
                            }
                        />
                        <br></br>

                        <ItemSeccion
                            position='left'
                            urlImg={img2}
                            titulo='12.000 m2 pensados para vos!'
                            descripcion={<Fragment>
                            <ul className="text-left">
                                <li>Muchos árboles en plena naturaleza para pasar un día buenísimo.</li>
                                <li>Cancha de Fútbol - Tenis sobre piso de cemento.</li>
                                <li>Aro de Basquet sobre piso de cemento.</li>
                                <li>Arco para penales.</li>
                                <li>Cancha de Voley.</li>
                                <li>Cancha de Tejo.</li>
                                <li>Rayuela.</li>
                                <li>Ta-Te-Ti (humano y con fichas)</li>
                                <li>Carpa gigante con capacidad para 100 personas con escenario.</li>
                                <li>Plaza con bancos.</li>
                                <li>Lagos con peces de colores</li>
                                <li><strong>Para los más chiquitos:</strong> Casita de las Hadas y Plaza infantil</li>
                                <li><strong>Para colegios religiosos:</strong> vía crucis con imágenes de 1,80 x 1,50 con código QR en cada estación para revivir cada una de ellas. Caverna Mariana y gruta de San Cayetano.</li>
                            </ul>
                            </Fragment>}
                        />
                        <br></br>
                        <ItemSeccion
                            position='left'
                            urlImg={img1}
                            titulo='Actividades Opcionales'
                            descripcion={
                            <ul className="text-left">
                               <li>Fogón con sales de los deseos y/o músicos en vivo.</li>
                               <li><strong>Juegos nocturnos: </strong>recorrido con linternas por el Laberinto al caer la tarde.</li>
                               <li>Almuerzo, cena o merienda.</li>
                            </ul>
                            }
                        />
                        <br></br>
                        <div className="pequeña-bajada">  
                            <p>Se permite ingresar con alimentos elaborados y bebidas (no alcohol).</p>
                          <a className="btn-contingentes" href="https://api.whatsapp.com/send?phone=541169354649&text=Hola!%20quisiera%20obtener%20mas%20informaci%C3%B3n%20para%20contingentes."><i className="fa fa-whatsapp"></i>&nbsp;&nbsp;Consultas y reservas</a>
                        </div>
                        <br></br>
                        
                    </div>
                </div>  
            </div>
     );
}
 
export default Contingentes;
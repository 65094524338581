import React, { useState, useEffect } from "react";
import imgEntrada from "../../img/entrada.jpg";
import clienteAxios from "../admin/config/axios";

const Horarios = () => {
  const [horarios, modificarHorarios] = useState({
    apertura: "",
    cierre: "",
  });

  useEffect(() => {
    clienteAxios.get("/api/horarios").then(function (res) {
      modificarHorarios({
        ...horarios,
        apertura: res.data.apertura,
        cierre: res.data.cierre,
      });
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg margen-arriba">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="datos-horarios">
              <h2>Horarios del Parque</h2>
              <p style={{ marginBottom: "5" }}>
                <br></br>
                El parque se encuentra abierto todos los días de {horarios.apertura} a {horarios.cierre} Hs.{" "}
                <br></br>
              </p>
              <p className="proto-covid_horarios">
                Se recuerda que por la situación Epidemiologica que atraviesa
                nuestro País, se realizará control de aforo, distanciamiento
                social y será obligatorio el ingreso con tapaboca y nariz
                debidamente colocado.<br></br>Contaremos con varios puntos de
                Sanitización dentro del parque para cuidarnos entre todos.
                <br></br>SI TENES SÍNTOMAS COMPATIBLES CON COVID-19 POR FAVOR,
                NO ASISTAS AL PARQUE
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <img className="img-horarios" src={imgEntrada} alt="Horarios" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Horarios;

import React,{ useReducer } from 'react';
import authContext from './authContext';
import authReducer from './authReducer';
import clienteAxios from '../../components/admin/config/axios';
import tokenAuth from '../../components/admin/config/token';
import { useHistory } from "react-router-dom";
import { 
    OBTENER_USUARIO,
    LOGIN_EXITOSO,
    LOGIN_ERROR,
    CERRAR_SESION,
 } from '../../types';

 const AuthState = props => {
    const initialState = {
        token: localStorage.getItem('token'),
        autenticado: null,
        usuario: null,
        mensaje: null,
        cargando: true
    }

    const [state, dispatch] = useReducer(authReducer,initialState);
    let history = useHistory();
    // retorna el usuario autenticado
    const usuarioAutenticado = async () => {
        
        const token = localStorage.getItem('token');
        if(token) {
            tokenAuth(token);

            const respuesta = await clienteAxios.get('/api/login');
           
            dispatch({
                type: OBTENER_USUARIO,
                payload: respuesta.data
            });
        }else {
            
            history.push("/admin");
            dispatch({
                type: LOGIN_ERROR
            });
        }
   
         
        
    
    }

    // cuando el usuario inicia sesion
    const iniciarSesion = async datos => {
        try {
            const respuesta = await clienteAxios.post('/api/login',datos);
            
            dispatch({
                type: LOGIN_EXITOSO,
                payload: respuesta.data
            });

            //obtener usuario
            usuarioAutenticado();
            


        } catch (error) {
            const alerta = {
                msg: 'Usuario y/o Clave Incorrectos',
                categoria: 'alerta-error'
            }
            dispatch({
                type: LOGIN_ERROR,
                payload: alerta
            })
        }
    }

    // cierra la sesion del usuario
    const cerrarSesion = () => {
        dispatch({
            type: CERRAR_SESION
        })
    }

    return(
        <authContext.Provider
            value={{
                token: state.token,
                autenticado: state.autenticado,
                usuario: state.usuario,
                mensaje: state.mensaje,
                cargando: state.cargando,
                iniciarSesion,
                usuarioAutenticado,
                cerrarSesion
            }}
        >{props.children}
        </authContext.Provider>
    )
 }

 export default AuthState;
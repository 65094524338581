import Modal from "../Modal";
import React,{useEffect} from "react";

import Header from "../Header/Header";
import Section from "../Section/Section";
import Sponsors from "../Sponsors";

const Home = () => {
    const invocaModal = React.useRef(null);
    

    function isMobile(){
        return (
            (navigator.userAgent.match(/Android/i)) ||
            (navigator.userAgent.match(/webOS/i)) ||
            (navigator.userAgent.match(/iPhone/i)) ||
            (navigator.userAgent.match(/iPod/i)) ||
            (navigator.userAgent.match(/iPad/i)) ||
            (navigator.userAgent.match(/BlackBerry/i))
        );
    }

    useEffect(() => {
        if(isMobile()) {
            invocaModal.current.click();
        }
    },[]);
  return (
    <>
      <div className="bg">
        <Header />
        <Section />
        <Sponsors />
      </div>
      <Modal invocaModal={invocaModal} />
    </>
  );
};

export default Home;

import React from 'react';

const inputCargarVideoYT = ({actualizarState, url_video}) => {
    return ( 
        <div className="form-group">
            <label for="video">Url video youtube:</label>
            <input name="url_video" type="text" className="form-control" id="video" placeholder="Ingrese URL de video de youtube" value={url_video} onChange={actualizarState} />
        </div>
     );
}
 
export default inputCargarVideoYT;
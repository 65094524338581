import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import clienteAxios from "../../admin/config/axios";
import { useHistory } from "react-router-dom";

const Novedades = () => {
  const [novedades, setNovedades] = useState([]);
  const history = useHistory();
  useEffect(() => {
    clienteAxios.get("/api/novedades").then(function (res) {
      setNovedades(res.data.novedades);
    });
  }, []);

  const updateNovedad = (index) => {
    history.push(`/admin/editarNovedad/${novedades[index].id}`);
  };
  const deleteNovedad = async (index) => {
    if (
      window.confirm("¿Esta Seguro que desea eliminar la novedad seleccionada?")
    ) {
      let dataFilter = novedades.filter(
        (item) => item.id !== novedades[index].id
      );
      setNovedades(dataFilter);
      await clienteAxios.post("/api/novedades/eliminarNovedad", {
        id: novedades[index].id,
      });
    }
  };
  return (
    <div
      style={{
        background: "#E4E3E3",
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
      }}
    >
      <h1 className="title">ADMINISTRAR NOVEDADES</h1>
      <div className="text-center d-flex align-items-center justify-content-center">
        <Link
          style={{
            width: "50px",
            fontSize: "22px",
            color: "#204051",
            marginBottom: "22px",
            marginRight: "40px",
          }}
          className=""
          to={"/admin/dashboard"}
          title="Volver al Menu Principal"
        >
          <i className="fas fa-arrow-circle-left"></i>
        </Link>
        <Link
          style={{ width: "200px" }}
          className="boton-dashboard"
          to={"/admin/agregarNovedad"}
        >
          AGREGAR NOVEDAD
        </Link>
      </div>

      <br />
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <div className="header_fijo">
            <table
              style={{
                height: "350px",
                overflowY: "scroll",
                overflowX: "hidden",
                position: "relative",
              }}
              className="table"
            >
              <thead className="table-dark">
                <tr>
                  <th scope="col">TITULO</th>
                  <th scope="col">DESCRIPCION</th>
                  <th scope="col">IMAGEN</th>
                  <th scope="col">VIDEO</th>
                  <th scope="col">ACCIONES</th>
                </tr>
              </thead>
              <tbody>
                {novedades.map(function (novedad, index) {
                  return (
                    <tr key={novedad.id}>
                      <th scope="row">{novedad.titulo}</th>
                      <td>{novedad.descripcion}</td>
                      <td>
                        {novedad.url_img != null ? (
                          <img
                            src={novedad.url_img}
                            width="80px"
                            alt={novedad.titulo}
                          />
                        ) : (
                          <p>--</p>
                        )}
                      </td>
                      <td>
                        {novedad.url_video != null ? (
                          <p>{novedad.url_video}</p>
                        ) : (
                          <p>--</p>
                        )}
                      </td>
                      <td>
                        <button
                          className="btn btn-sm btn-success mr-2"
                          onClick={() => updateNovedad(index)}
                        >
                          <i className="fas fa-edit"></i>
                        </button>
                        <button
                          className="btn btn-sm btn-danger"
                          onClick={() => deleteNovedad(index)}
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-md-1"></div>
      </div>
    </div>
  );
};

export default Novedades;

import React, {useState} from "react";
import { useEffect } from "react";
import clienteAxios from "../../admin/config/axios";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
const Horarios = () => {
    const history = useHistory();
    
    const [horarios, modificarHorarios] = useState({
        apertura: '',
        cierre: ''
    });

    useEffect(() => {
        clienteAxios.get("/api/horarios").then(function (res) {
            modificarHorarios({
                ...horarios,
                apertura: res.data.apertura,
                cierre: res.data.cierre
            });
        });
    },[]);
    
    const actualizarState = e => {
        modificarHorarios({
            ...horarios,
            [e.target.name]: e.target.value
        })
    }

  const handleSubmit = (e) => {
      e.preventDefault();
      clienteAxios.post("/api/ajustes/storeHorarios",{apertura: horarios.apertura, cierre:horarios.cierre});
      history.push("/admin/dashboard");
  };
  return (
    <div style={{ background: "#E4E3E3", height: "100vh", width: "100vw" }}>
       <h1 className="title text-center">ADMINISTRAR HORARIOS</h1>
    <div className="container">
    <Link
          style={{ width: "50px",fontSize:'22px',color: '#204051',marginBottom:'22px',marginRight:'40px' }}
          className=""
          to={"/admin/dashboard"}
          title="Volver al Menu Principal"
        >
          <i className="fas fa-arrow-circle-left"></i>
        </Link>
    
      <div className="row ">
        <div className="col-md-6 form-group">
          <label htmlFor="horario_apertura">Hora Apertura:</label>
          <input
            id="horario_apertura"
            name="apertura"
            type="time"
            className="form-control"
            onChange={actualizarState}
            defaultValue={horarios.apertura}
            required="required"
          />
        </div>
        <div className="col-md-6 form-group">
          <label htmlFor="horario_cierre">Hora Cierre:</label>
          <input
            id="horario_cierre"
            name="cierre"
            type="time"
            className="form-control"
            onChange={actualizarState}
            defaultValue={horarios.cierre}
            required="required"
          />
        </div>
        <br />
        <br />
      </div>
      <div className="row mt-5">
        <div className="col-md-12 text-center">
          <button className="btn btn-success" onClick={handleSubmit}>
            Guardar
          </button>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Horarios;

import React, {Fragment } from 'react';
import Slide from 'react-reveal/Slide';
import Slider from '../Slider';
import ItemSeccion from '../ItemSeccion';
import imgJuegos from '../../img/Juegos/juego1.jpeg';
import imgRayuela from '../../img/Juegos/rayuela.jpeg';
const Juegos = () => {
    
    const imagesSlider = [
        'Juegos/IMG_8982.JPG',
        'Juegos/IMG_8984.JPG',
        'Juegos/IMG_8987.JPG',
        'Juegos/tateti1.jpeg',
        'Juegos/tateti2.jpeg',
        'Juegos/IMG_8988.JPG',
        'Juegos/IMG_8990.JPG',
        'Juegos/IMG_9016.JPG',
        'Juegos/IMG_9017.JPG',
        'Juegos/IMG_9050.JPG',
        'Juegos/IMG_9053.JPG',
        'Juegos/arenero.jpeg',
        'Juegos/arenero2.jpeg',
        'Juegos/cartelRayuela.jpeg'
    ];
    
    return ( 
            <div className="bg margen-arriba">
                <Slide top>
                 <h2 className="title">SECTOR DE JUEGOS</h2>
                 </Slide>
                <div className="container">
                   
                    <Slider 
                        imagenes={imagesSlider}      
                    />
                    <div className="contenido">
                        
                        <ItemSeccion
                            position='left'
                            urlImg={imgRayuela}
                            titulo='Juegos'
                            descripcion={<Fragment><p>El sector de juegos convoca a toda la familia, cualquiera sea su edad. En el ingreso te espera una Rayuela y un Ta-Te-Ti llevándote a un viaje por el tiempo, reviviendo momentos de la infancia de muchos adultos y compartiendo con los más pequeños los juegos tradicionales. El arenero y la Casita de las Hadas serán el lugar elegido por los niños donde los papás podrán verlos disfrutar tomando unos ricos mates o un refresco.</p>
                           
                          </Fragment>}
                        />
                        <br></br>

                         <ItemSeccion
                            position='left'
                            urlImg={imgJuegos}
                            titulo='Deportes'
                            descripcion={<Fragment><p>Sobre base de cemento una cancha de fútbol tenis y un aro de básquet y sobre arena los mejores arqueros atajarán penales, un seleccionado de vóley jamás visto mostrará su formación y los expertos en tejo demostrarán las habilidades playeras. Todos los elementos para la práctica son provistos de forma gratuita.</p></Fragment>}
                        />
                        
                        <br></br>

                    </div>
                    
                
                </div>
                
            </div>
     );
}
 
export default Juegos;
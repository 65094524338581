import React, { useState, useContext, useEffect } from "react";
import AlertaContext from "../../../context/alertas/alertaContext";
import authContext from "../../../context/autenticacion/authContext";

const Login = (props) => {
  // extraer los valores del context
  const alertaContext = useContext(AlertaContext);
  const { alerta, mostrarAlerta } = alertaContext;

  const AuthContext = useContext(authContext);
  const { mensaje, autenticado, iniciarSesion } = AuthContext;

  // en caso de que el password o usuario no exista
  useEffect(() => {
    if (autenticado) {
      props.history.push("/admin/dashboard");
    }

    if (mensaje) {
      mostrarAlerta(mensaje.msg, mensaje.categoria);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mensaje, autenticado, props.history]);

  const [formulario, modificarForm] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formulario;

  const actualizarState = (e) => {
    modificarForm({
      ...formulario,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    //Pasarlo al action
    iniciarSesion({ email, password });
  };

  return (
    <div style={{ background: "#E4E3E3", height: "100vh", width: "100vw" }}>
       <h1 className="title">INGRESAR A ADMINISTRACIÓN</h1>
    <div className="container">
      <br />
      <div className="row">
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <form onSubmit={handleSubmit} className="col-md-12">
            {alerta ? (
              <div className="text-center">
              <div className={`alerta ${alerta.categoria}`}>{alerta.msg}</div>
              </div>
            ) : null}
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                className="form-control"
                name="email"
                id="email"
                onChange={actualizarState}
                value={email}
                placeholder="Ingrese Email"
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Clave</label>
              <input
                type="password"
                className="form-control"
                name="password"
                id="password"
                onChange={actualizarState}
                value={password}
                placeholder="Ingrese Clave"
              />
            </div>
            <div className="text-center mt-5">
              <button
                type="submit"
                style={{ background: "#3B6978" }}
                className="btn btn-primary"
              >
                Ingresar
              </button>
            </div>
          </form>
        </div>
        <div className="col-md-3"></div>
      </div>
    </div>
    </div>
  );
};

export default Login;

import React from 'react';

const inputCargarImagen = ({subirArchivo}) => {
    return ( 
        <div className="custom-file">
            <input type="file" name="imagen" className="custom-file-input" onChange={subirArchivo} id="customFile" />
            <label className="custom-file-label" for="customFile">Elija una Imagen</label>
        </div>
     );
}
 
export default inputCargarImagen;
import React from 'react';

const CardAcordeon = (props) => {
    return (
        
        <div className="card">
            <div className="card-header" id={props.idHeader}>
                <h5 className="mb-0">
                    <button className="btn " data-toggle="collapse" data-target={'#'+props.idCollapse} aria-expanded="true" aria-controls={props.idCollapse}>
                        {props.titulo}
                    </button>
                </h5>
            </div>
            <div id={props.idCollapse} className="collapse" aria-labelledby={props.idHeader} data-parent="#accordion">
                <div className="card-body">
                    {props.descripcion}
                </div>
           </div>
        </div>
    );
}
 
export default CardAcordeon;
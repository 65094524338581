import React from 'react';

const ComoLlegar = () => {
    return (
        <div className="bg margen-arriba">
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <div className="datos-ubi">
                        <h2>¿Como Llego al Parque?</h2>
                        <p>El Laberinto se encuentra en Las Toninas, unos de los principales destinos turísticos del Partido de La Costa, ubicado sobre la Av. 7 y esquina 16, a solo 5 minutos de la terminal. Al encontrarse en el corazón de las Toninas, su ubicación es privilegiada... no podés dejar de visitarnos!!</p>
                        
                        <ul className="social-network social-circle">
                            <li><a href="/#" className="icoFacebook" title="En Auto"><i className="fas fa-car"></i></a></li>
                            <li><a href="/#" className="icoYoutube" title="Caminando"><i className="fas fa-walking"></i></a></li>
                            <li><a href="/#" className="icoInsta" title="En colectivo"><i className="fas fa-bus"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-6">
                        <iframe
                            title="mapa google"
                            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBgehdcVgILbd7408t60lYaCZqFQbZunFA
                                &q=Parque+Temático+Laberinto+De+Las+Toninas" 
                        />
                </div>
            </div>
        </div>
        </div>
     );
}
 
export default ComoLlegar;
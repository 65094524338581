import React,{ Fragment } from 'react';
import Slide from 'react-reveal/Slide';
import Slider from '../Slider';
import ItemSeccion from '../ItemSeccion';

import imgRadio from '../../img/servicios/nuevas/radio.jpeg';
import imgArtesanias from '../../img/servicios/nuevas/imgArtesanias.jpg';
const Servicios = () => {

    const imagesSlider = [
        'servicios/nuevas/servi1.jpeg',
        'servicios/nuevas/servi2.jpeg',
        'servicios/nuevas/servi3.jpeg',
        'servicios/nuevas/servi4.jpeg'
       
    ];

    return ( 
        <div className="bg margen-arriba">
            <Slide top>
                <h2 className="title">SERVICIOS DEL PARQUE</h2>
            </Slide>
            <div className="container">
                <Slider 
                    imagenes={imagesSlider}      
                />
                <div className="contenido">
                    <ItemSeccion
                        position='left'
                        urlImg={imgArtesanias}
                        titulo='Principales Servicios'
                        descripcion={<Fragment><p>Todo preparado para que pases un día mágico. Encontrarás distintos tipos de servicios tales como: kiosco, gastronomía, fotografía, Wifi gratuito en todo el predio, productos artesanales, una carpa gigante con bancos, mesas y sillas y un escenario donde se presentan obras a la gorra.</p>
                        <p>En el predio tenemos distribuidas mesas, sillas y bancos al aire libre y un sector de mantas y reposeras.</p>
                        <p>Sanitarios, uno de ellos accesible que incluye cambiador para bebés.</p>
                        <p><strong>No se permite el ingreso con mascotas cualquiera sea el tamaño y la especie.</strong></p>
                        </Fragment>}
                    />
                    <br></br>
                    
                    <ItemSeccion 
                        position='left'
                        urlImg={imgRadio}
                        titulo='FM Laberinto'
                        descripcion={<Fragment><p>Contamos con FM Laberinto 100.9, ubicada dentro del predio  en una cabaña de estilo barilochense, su objetivo es comunicar todos los atractivos turísticos existentes y divulgar los eventos culturales del Partido de La Costa.</p></Fragment>}
                    />
                    <br></br>
                   
                </div>
            </div>
         </div>
     );
}
 
export default Servicios;
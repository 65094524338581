import React, { useEffect, useState } from "react";
import InputCargarImagen from "../../admin/inputCargarImagen";
import InputCargarVideoYT from "../../admin/inputCargarVideoYT";
import clienteAxios from "../../admin/config/axios";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";

const AgregarNovedad = ({ accion }) => {
 


  let history = useHistory();

  let { id } = useParams();
  const [cargarMedia, mostrarCargarMedia] = useState(0);
  const [formulario, modificarForm] = useState({
    titulo: "",
    descripcion: "",
    imagen: null,
    url_video: "",
  });

  useEffect(() => {
    if (id) {
      clienteAxios
        .post("/api/novedades/getOneNovedad", { id: id })
        .then(function (res) {
          modificarForm({
            ...formulario,
            titulo: res.data.novedad.titulo,
            descripcion: res.data.novedad.descripcion,
            imagen: res.data.novedad.url_img,
            url_video: res.data.novedad.url_video,
          });
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { titulo, descripcion, url_video } = formulario;

  const actualizarState = (e) => {
    modificarForm({
      ...formulario,
      [e.target.name]: e.target.value,
    });
  };
  const subirArchivo = (e) => {
    modificarForm({
      ...formulario,
      imagen: e.target.files[0],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const f = new FormData();
    f.append("titulo", formulario.titulo);
    f.append("descripcion", formulario.descripcion);
    if (formulario.imagen != null) {
      f.append("imagen", formulario.imagen);
    }
    if (formulario.url_video !== null || formulario.url_video !== "" || formulario.url_video !== undefined) {
      f.append("video", formulario.url_video);
    }
    if (id) {
      f.append("id", id);
    }

    if (id) {
      // editar
      clienteAxios.post("/api/novedades/updateNovedad", f).then(function (res) {
        history.push("/admin/novedades");
      });
    } else {
      // insertar
      clienteAxios.post("/api/novedades", f).then(function (res) {
        history.push("/admin/novedades");
      });
    }
  };

  return (
    <div style={{ background: "#E4E3E3", height: "100vh", width: "100vw" }}>
      <h1 className="title text-center">{(!id) ? 'AGREGAR NUEVA NOVEDAD' : 'EDITAR NOVEDAD'}</h1>
      <div className="container">
        <div className="row">
          <div className="offset-md-2 col-md-8">
            <form onSubmit={handleSubmit} encType="multipart/form-data">
             
              <div className="form-group">
                <label htmlFor="titulo">Titulo:</label>
                <input
                  type="text"
                  className="form-control"
                  name="titulo"
                  id="titulo"
                  placeholder="Ingrese Titulo"
                  defaultValue={titulo}
                  onChange={actualizarState}
                 
                />
              </div>
              <div className="form-group">
                <label htmlFor="descripcion">Descripción</label>
                <textarea
                  className="form-control"
                  name="descripcion"
                  id="descripcion"
                  cols="30"
                  rows="6"
                  defaultValue={descripcion}
                  onChange={actualizarState}
                 
                ></textarea>
              </div>
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  className="custom-control-input"
                  id="imagen"
                  name="radio-stacked"
                  onClick={() => mostrarCargarMedia(1)}
                  value="1"
                 
                />
                <label className="custom-control-label" htmlFor="imagen">
                  Imagen
                </label>
              </div>
              {cargarMedia === 1 ? (
                <InputCargarImagen subirArchivo={subirArchivo} />
              ) : null}
              <div className="custom-control custom-radio mb-3">
                <input
                  type="radio"
                  className="custom-control-input"
                  id="video"
                  name="radio-stacked"
                  onClick={() => mostrarCargarMedia(2)}
                  value="2"
                  
                />
                <label className="custom-control-label" htmlFor="video">
                  Video
                </label>
              </div>
              {cargarMedia === 2 ? (
                <InputCargarVideoYT
                  actualizarState={actualizarState}
                  url_video={url_video}
                />
              ) : null}
              <button
                type="submit"
                className="btn btn-primary d-block ml-auto mr-auto"
              >
                Guardar
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgregarNovedad;

import React, { useEffect, useState } from "react";
import clienteAxios from "../../admin/config/axios";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
const Marquesina = () => {
  const [marquesina, modificarMarquesina] = useState("");
  let history = useHistory();
  useEffect(() => {
    clienteAxios.get("/api/marquesina").then(function (res) {
      modificarMarquesina(res.data.marquesina);
    });
  }, []);

  const actualizarState = (e) => {
    modificarMarquesina(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    clienteAxios.post("/api/ajustes/storeMarquesina", {
      marquesina: marquesina,
    });
    history.push("/admin/dashboard");
  };

  return (
    <div style={{ background: "#E4E3E3", height: "100vh", width: "100vw" }}>
      <h1 className="title text-center">ADMINISTRAR MARQUESINA</h1>
      <div className="container">
      <Link
          style={{ width: "50px",fontSize:'22px',color: '#204051',marginBottom:'22px',marginRight:'40px' }}
          className=""
          to={"/admin/dashboard"}
          title="Volver al Menu Principal"
        >
          <i className="fas fa-arrow-circle-left"></i>
        </Link>
        <div className="row ">
          <div className="col-md-12 text-center">
        
            <textarea
              id="editor-marquesina"
              name="editor-marquesina"
              cols="80"
              rows="10"
              value={marquesina}
              onChange={actualizarState}
            ></textarea>
            <br />
            <br />
            <button className="btn btn-success" onClick={handleSubmit}>
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Marquesina;

import React from 'react';
import Slide from 'react-reveal/Slide';
import Slider from '../Slider';
import ItemSeccion from '../ItemSeccion';
import imgLaberinto from '../../img/laberinto/IMG_9048.JPG';

const Laberinto = () => {
    
    const imagesSlider = [
        'laberinto/IMG_9005.JPG',
        'laberinto/IMG_9027.JPG',
        'laberinto/IMG_9037.JPG',
        'laberinto/IMG_9039.JPG',
        'laberinto/IMG_9041.JPG',
        'laberinto/IMG_9042.JPG',
        'laberinto/IMG_9043.JPG',
        'laberinto/IMG_9045.JPG',
        'laberinto/troncomovil1.jpeg',
        'laberinto/foto_barco.jpg',
        'laberinto/troncomovil2.jpeg',
        'laberinto/foto_barco2.jpg',
        
    ];

    return (
        <div className="bg margen-arriba">
            <Slide top>
             <h2 className="title">JUEGO DEL LABERINTO</h2>
            </Slide>
            <div className="container">
                <Slider 
                    imagenes={imagesSlider}      
                />
                <div className="contenido">
                    <ItemSeccion
                        position='left'
                        urlImg={imgLaberinto}
                        titulo='Laberinto'
                        descripcion={<div><p>En Las Toninas, Partido de La Costa, emerge uno de los Laberintos más Grandes de Sudamérica, en un predio de 12.000 m2.</p>
                        <p>Completamente hecho de cerco vivo, con paredes de hasta 5 metros de alto, sobre un médano natural de arena floja, lo convierten en único en el mundo por contar con subidas, bajadas, escalones y a metros del mar. Cinco entradas, una sola correcta y 1.700 metros de senderos harán que vivas una aventura fascinante recorriendo 14 caminos, algunos de ellos sin salida haciéndote retroceder en el juego. Un barco pirata y el auto de los Picapiedras serán parte de la aventura en el extenso camino buscando llegar al mirador de 10 metros de alto desde donde se podrá ver la salida.</p></div>}
                    />
                    <br></br>
                    
                  
                </div>
            </div>
        </div>
        
     );
}
 /**
  * {contenido ?
                 <Fragment>
                     <button className="btn btn-success" onClick={() => modificarContenido(false)} type="button" >Laberinto</button>
                <button className="btn btn-success" onClick={() => modificarContenido(true)} type="button" >Mangrullo</button>
                    <div className="col-md-6">
                        <img src={imgLaberinto} width="100%" />
                    </div>
                    <div className="col-md-6">
                        <p>Construido en madera con una altura de 10 mts erguido en el centro del laberinto y siendo su objetivo final del juego, con capacidad para 10 personas en su parte superior con red de seguridad en el habitáculo, permitiendo tener vista panorámica del juego, el predio y sus alrededores incluso vista al mar.</p> 
                    </div>
                 </Fragment>
                :
                <Fragment>
                    <p>En Las Toninas emerge uno de los mas importantes atractivos turísticos del Partido de la Costa. En un predio de 10.000 mts2 conviven por la parte temática un Vía Crucis lumínico con gigantografias, Gruta de San Cayetano Caverna Mariana y distintas capillas, cuenta con carpa gigante con distintas butacas, sector de compras y gastronomía, sector de juegos y entretenimientos junto a FM local 100.9 Radio Laberinto y por el sector lúdico el laberinto más grande de la República Argentina de 6400 mts2.</p>
                    <p>El Laberinto tiene 5 entradas pero solo 1 te llevara al mirado, objetivo principal del juego que te guiara a la salida, su superficie equivale a 80mts x 80mts; a lo largo de su recorrido vas a encontrar 12 caminos que no tienen salida y 5 que te hacen retroceder en el juego para que tomes otro sendero que sea el correcto para llegar al final del juego. El laberinto forestal construido sobre médanos naturales lo convierten en único en el mundo por contar con subidas, bajadas y escalones realizados en caminos de arena floja.</p>
                </Fragment>
                }
  */
export default Laberinto;
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Home from './components/pages/Home';
import Horarios from './components/pages/Horarios';
import ComoLlegar from './components/pages/ComoLlegar';
import PregFrecuentes from './components/pages/PregFrecuentes';
import Contingentes from './components/pages/Contingentes';
import Accesibilidad from './components/pages/Accesibilidad';
import Contacto from './components/pages/Contacto';
import Laberinto from './components/pages/Laberinto';
import Tematico from './components/pages/Tematico';
import Juegos from './components/pages/Juegos';
import Servicios from './components/pages/Servicios';
import Novedades from './components/pages/Novedades';

import LayoutCliente from './components/layouts/LayoutCliente';
import Login from './components/pages/admin/Login';
import Dashboard from './components/pages/admin/Dashboard';
import AdminNovedades from './components/pages/admin/Novedades';
import AgregarNovedad from './components/pages/admin/AgregarNovedad';
import AdminHorarios from './components/pages/admin/Horarios';
import Marquesina from './components/pages/admin/Marquesina';
import AuthState from './context/autenticacion/authState';
import AlertaState from './context/alertas/alertaState';
import RutaPrivada from './components/admin/rutas/RutaPrivada';
import tokenAuth from './components/admin/config/token';

const Routes = () => {
    
    // Revisar si tenemos un token
    const token = localStorage.getItem('token');
    if(token) {
      tokenAuth(token);
    }
 
    return (
       <Router>
           <Switch>
                
                    <Route path='/admin/:path?/:path?' exact>
                        <AuthState>
                        <AlertaState>
                            <Switch>
                                <Route exact path='/admin' component={Login} />
                                <RutaPrivada exact path="/admin/dashboard" component={Dashboard} />
                                <RutaPrivada exact path="/admin/novedades" component={AdminNovedades} />
                                <RutaPrivada exact path="/admin/agregarNovedad" component={AgregarNovedad} />
                                <RutaPrivada exact path="/admin/editarNovedad/:id?" accion="update" component={AgregarNovedad} />
                                <RutaPrivada exact path="/admin/horarios" component={AdminHorarios} />
                                <RutaPrivada exact path="/admin/marquesina" component={Marquesina} />
                            </Switch>
                        </AlertaState>
                        </AuthState>
                    </Route>
               
               <Route>
                    <LayoutCliente>
                        <Switch>
                            <Route exact path='/' component={Home} />
                            <Route exact path='/horarios'  component={Horarios} />
                            <Route exact path='/comoLlegar' component={ComoLlegar} />
                            <Route exact path='/pregFrecuentes' component={PregFrecuentes} />
                            <Route exact path='/contingentes' component={Contingentes} />
                            <Route exact path='/accesibilidad' component={Accesibilidad} />
                            <Route exact path='/contacto'   component={Contacto} />

                            <Route exact path='/laberinto' component={Laberinto} />
                            <Route exact path='/tematico' component={Tematico} />
                            <Route exact path='/juegos' component={Juegos} />
                            <Route exact path='/servicios' component={Servicios} />
                            <Route exact path='/novedades' component={Novedades} />
                        {/* <Route path="*" component={Home} /> */ }
                        </Switch>
                    </LayoutCliente>
               </Route>
               
            
                
            </Switch>
        </Router>  
     );
}
 
export default Routes;
import React from 'react';
import Carousel from "react-slick";

const Slider = ({imagenes}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    cssEase: "linear",
    autoplay: true,
    autoplaySpeed: 5000,
   
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
       
        }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
           
          }
        }
    ]
    
  };

    return (
      <div className="container">
        <Carousel {...settings}>
          {imagenes.map((item, i) => {
                return (
                  <div key={i}>
                    <img width="100%" height="200" src={require(`../img/${item}`)} alt="Imagen Carousel" />           
                  </div>
                  );
                })
          }         
        </Carousel>
      </div>
    
      
    );
}
 
export default Slider;

/**<div id="carouselExampleControls" classNameNameName="carousel slide" data-ride="carousel" align="center">
            <div classNameNameName="carousel-inner">
                {
                   imagenes.map((item, i) => {
                       let elemento;
                       if(i==0)
                       {
                           return (
                            <div key={i} id="item" classNameNameName="carousel-item active ">
                                <img classNameNameName="d-block w-100" src={require(`../img/laberinto/${item}`)} alt="First slide" />
                            </div>
                            );
                       }
                       else
                       {
                           return (<div key={i} id="item" classNameNameName="carousel-item ">
                            <img classNameNameName="d-block w-100" src={require(`../img/laberinto/${item}`)} alt="First slide" />
                            </div>);
                       } 
                    })
                }
            </div>
            <a classNameNameName="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                <span classNameNameName="carousel-control-prev-icon" aria-hidden="true"></span>
                <span classNameNameName="sr-only">Previous</span>
            </a>
            <a classNameNameName="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span classNameNameName="carousel-control-next-icon" aria-hidden="true"></span>
                <span classNameNameName="sr-only">Next</span>
            </a>
        </div>  */
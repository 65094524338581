import React, {Fragment} from 'react';
import Slider from '../Slider';
import Slide from 'react-reveal/Slide';
import ItemSeccion from '../ItemSeccion';
import imgEstacionamiento from '../../img/accesibilidad/estacionamiento2.jpg';
import imgBanios from '../../img/accesibilidad/image6.jpg';


const Accesibilidad = () => {

    const imagesSlider = [
        'accesibilidad/estacionamiento1.jpg',
        'accesibilidad/estacionamiento2.jpg',
        'accesibilidad/image1.jpg',
        'accesibilidad/image5.jpg',
        'accesibilidad/image6.jpg',
        'accesibilidad/image7.jpg',
        'accesibilidad/image8.jpg',
        'accesibilidad/image9.jpg',
        'accesibilidad/image10.jpg',
        'accesibilidad/image11.jpg',
        'accesibilidad/image14.jpg',
        'accesibilidad/image15.jpg',
    ];

    return ( 
        <div className="bg margen-arriba">
                <Slide top>
                    <h2 className="title">ACCESIBILIDAD</h2>
                </Slide>
                <div className="container">
                   
                    <Slider 
                        imagenes={imagesSlider}      
                    />
                    <div className="contenido">
                        
                        <ItemSeccion
                            position='left'
                            urlImg={imgEstacionamiento}
                            titulo='Estacionamiento Exclusivo'
                            descripcion='Hace mucho tiempo venimos trabajando en el Laberinto de Las Toninas para convertirlo en un sitio accesible para personas con movilidad reducida.
                            Desde el ingreso contamos con estacionamiento exclusivo para ascenso y descenso.'
                        />
                        
                        <br></br>
                        <ItemSeccion 
                            position='left'
                            urlImg={imgBanios}
                            titulo='Recorrido y Sanitario Accesible'
                            descripcion={<Fragment><p>Desde allí unos 300 metros de camino compacto facilitan la circulación en silla de ruedas, cochecitos para bebés, personas con muletas, etc.,  permitiendo recorrer la parte temática, los puestos comerciales, la carpa gigante y los sanitarios (uno de ellos adaptado). También disponemos de una silla de ruedas a disposición de los visitantes y dos bancos para personas obesas, elementos indispensables para una cómoda estadía en el lugar.</p>
                            <p>Debido a que el Laberinto está construido sobre médanos naturales y cuenta con subidas, bajadas, escalones y caminos de arena floja, no es posible el acceso al mismo con sillas de ruedas y/o dificultades motrices.</p></Fragment>}
                        />
                         <br></br>
                       
                    
                
                </div>
                
            </div>
        </div>
     );
}
 
export default Accesibilidad;
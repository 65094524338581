import React, {Fragment} from 'react';
import Slider from '../Slider';
import Slide from 'react-reveal/Slide';
import ItemSeccion from '../ItemSeccion';
import imgViaCrucis from '../../img/tematico/via5.jpeg';
import imgTematico2 from '../../img/tematico/tematico2.jpeg';
import imgTematico4 from '../../img/tematico/tematico4.jpeg';

const imagesSlider = [
    
    'tematico/IMG_8991.JPG',
    'tematico/IMG_8992.JPG',
    'tematico/IMG_8996.JPG',
    'tematico/via2.jpeg',
    'tematico/via3.jpeg',
    'tematico/via4.jpeg',
    'tematico/via7.jpeg',
    'tematico/IMG_9009.JPG',
    'tematico/IMG_9014.JPG',
    'tematico/IMG_9015.JPG',
    'tematico/tematico1.jpeg',
    'tematico/tematico3.jpeg'
   
];

const Tematico = () => {
    return ( 
        <div className="bg margen-arriba">
             <Slide top>
                <h2 className="title">PARTE TEMÁTICA</h2>
             </Slide>
            <div className="container">
               
                <Slider 
                    imagenes={imagesSlider}      
                />
                <div className="contenido">
                    
                    <ItemSeccion
                        position='left'
                        urlImg={imgViaCrucis}
                        titulo='Via Crucis'
                        descripcion={<><p>Cada estación representada por gigantografías luminosas de 1,80 x 1,50  nos muestra el camino a la cruz que debió emprender Jesús desde que fue capturado hasta su crucifixión. Las imágenes tienen un código QR que al leerlo con el dispositivo te llevarán a representar en la imaginación y en la memoria mediante audios, éste recorrido espiritual el mismo que hizo Jesús hasta el monte Calvario mientras cargaba la cruz.</p>
                        <p>Semana Santa es vivida por miles de fieles (unas 6.000 personas) que concurren y acompañan desde la Capilla de San Cayetano ubicada a 3 cuadras del lugar, donde la representación de la última cena y lavado de pies se realizan en el atrio de la mencionada Capilla. En procesión de antorchas los actores caracterizados acorde a la época, peregrinan hacia el Parque Temático acompañados por miles de personas. Cinco escenarios serán testigos del juzgamiento y crucifixión interpretados por 120 actores (vecinos de la localidad). La resurrección emociona a todos cuando Jesús es elevado a unos 7 metros de altura entre fuegos artificiales insonoros que iluminan la noche. Una pantalla gigante transmite en directo al igual que distintos canales de TV de la zona y nacionales.</p></>}
                    />
                    <br></br>
                    
                    <ItemSeccion 
                        position='left'
                        urlImg={imgTematico4}
                        titulo='Gruta de San Cayetano y Caverna Mariana'
                        descripcion={<Fragment><p>Al descender de la última estación del Vía Crucis, nos encontramos con la <strong>Gruta de San Cayetano</strong> patrono de Las Toninas santo de la providencia, patrono del pan, la salud y el trabajo. Fue construida en el año 2011 con 40 toneladas de roca colocadas una sobre otra en forma de pirca. Un lago con peces de colores en el frente y el encantador sonido de 3 cascadas de agua nos introducen a un momento de paz.</p>
                        <p>La<strong> Caverna Mariana</strong> tiene 7 advocaciones de la Virgen María y es considerada una de las cavernas más importantes por su construcción y debido a que sus imágenes lucen la vestimenta original (no pintadas).</p>
                        <p>La procedencia de dichas imágenes es por donaciones de forma particular o bien por sus provincias de origen, tal es el caso de Salta y Catamarca.</p>
                      </Fragment>}
                    />
                    <br></br>
                    <ItemSeccion 
                        position='left'
                        urlImg={imgTematico2}
                        titulo='Lago de los Milagros y Recorrido'
                        descripcion={<Fragment><p>En el <strong>Lago de los Milagros</strong> se encuentra la imagen de Stella Maris, patrona de los navegantes, donada por la Armada Argentina.</p>
                            <p>Continuando con el recorrido, <strong>9 capillas altas y 4 bajas</strong> ubicadas a los laterales de un boulevard dividido por canteros con flores, se encuentran más imágenes donde los fieles dejan sus peticiones y ofrendas.</p></Fragment>}
                    />
                </div>
            </div>
        </div>
     );
}
 
export default Tematico;
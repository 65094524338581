import React, { useEffect, useState } from "react";
import Slide from "react-reveal/Slide";
import ItemSeccion from "../ItemSeccion";
import clienteAxios from "../admin/config/axios";
import Marquee from "react-fast-marquee";

const Novedades = () => {
  const [novedades, setNovedades] = useState([]);
  const [marquesina, setMarquesina] = useState("");

  useEffect(() => {
    clienteAxios.get("/api/novedades").then(function (res) {
      setNovedades(res.data.novedades);
    });
    clienteAxios.get("/api/marquesina").then(function (res) {
      setMarquesina(res.data.marquesina);
    });
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="bg margen-arriba">
        <Slide top>
          <h2 className="title">ÚLTIMAS NOVEDADES DEL PARQUE</h2>
        </Slide>
        <div className="container">
          <div className="row mb-4">
            <div className="col-md-12 marquee">
              <Marquee speed="40" gradient={false}>
                {marquesina}
              </Marquee>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2"></div>
            <div id="contenedor-novedades" className="col-md-8">
              {novedades.length > 0 ? (
                novedades.map(function (novedad) {
                  if (novedad.titulo === "" && novedad.descripcion === "") {
                    return (
                      <Slide bottom key={novedad.id}>
                        <div className="card mb-3 labe-1">
                          <div className="row no-gutters">
                            <div className="col-md-12">
                              <div className="p-0 card-body">
                                {novedad.url_img != null ? (
                                  <img
                                    src={novedad.url_img}
                                    className="card-img"
                                    alt="..."
                                  />
                                ) : (
                                  <div className="row">
                                    <div className="col-md-3"></div>
                                    <div className="col-md-6">
                                      <iframe
                                        className="iframe-novedad-sinvideo iframe-novedad"
                                        src={novedad.url_video}
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                      ></iframe>
                                    </div>
                                    <div className="col-md-3"></div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Slide>
                    );
                  } else {
                    return (
                      <ItemSeccion
                        idNovedad={novedad.id}
                        key={novedad.id}
                        position="left"
                        urlImg={novedad.url_img}
                        titulo={novedad.titulo}
                        descripcion={<p>{novedad.descripcion}</p>}
                        video={novedad.url_video}
                      />
                    );
                  }
                })
              ) : (
                <h2 className="no-hay-novedades text-center mt-5 mb-5 parpadea">
                  No hay novedades Cargadas...
                </h2>
              )}
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </div>
    
    </>
  );
};

export default Novedades;
